import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService, HandleErrorsService } from 'app/app-services';
import { AdminQuery } from 'app/app-services/admin.service';
import { Admin } from 'app/models/admin.model';
import { EntityList } from 'app/models/entity-list.model';
import { ExtendedLogging, FilterQuery } from 'app/models/loggin.model';
import { EMPTY, Observable, ReplaySubject, Subject, catchError, combineLatest, map, of, switchMap, takeUntil } from 'rxjs';
import { LoggingService } from '../app-services/logging.service';
@Component({
  selector: 'app-super-admin-credit-log',
  templateUrl: './super-admin-credit-log.component.html',
})
export class SuperAdminCreditLogComponent implements OnInit, OnDestroy {
  private readonly unsubscribeSubject: Subject<void>;
  loggings = new ReplaySubject<EntityList<ExtendedLogging>>(1);
  adminList = new ReplaySubject<Admin[]>(1);

  searchedAdmins$: Observable<Admin[]>;

  searchedDates = { endDate: null, startDate: null };
  offset$ = of(0);
  constructor(
    private readonly adminService: AdminService,
    private readonly loggingService: LoggingService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly handleErrors: HandleErrorsService,
  ) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(
        switchMap(([params, queryParams]) => {
          const query = {};

          if (params['page']) {
            query['offset'] = (+params['page'] - 1) * 10;
            this.offset$ = of(+params['page'] - 1);
          }
          Object.keys(queryParams).map(k => {
            switch (k) {
              case 'adminIds':
                query['adminIds'] = queryParams['adminIds'].split(',');

                this.searchedAdmins$ = this.adminService.getAdminList({ ids: query['adminIds'] }).pipe(
                  map(result => result.data.listing),
                  catchError(error => {
                    this.handleErrors.handleErrors(error);

                    return EMPTY;
                  }),
                );

                break;

              default:
                query[k] = queryParams[k];
                this.searchedDates[k] = queryParams[k];
                break;
            }
          });

          return this.loggingService.getLoggingList(query);
        }),
        takeUntil(this.unsubscribeSubject),
      )
      .subscribe({
        next: response => {
          this.loggings.next(response.data);
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
  }

  pageNavigation(event: { count: number; pageSize: number; limit: number; offset: number }) {
    const queryParams = this.route.snapshot.queryParams;
    this.router.navigate(['/super-admin-credit-history', event.offset + 1], { queryParams });
  }

  filterLogging(queryParams: FilterQuery) {
    this.router.navigate(['super-admin-credit-history'], { queryParams });
  }

  fetchAdmins(query: AdminQuery) {
    this.adminService.getAdminList(query).subscribe({
      next: response => {
        this.adminList.next(response.data.listing);
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }
}
