import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HandleErrorsService } from 'app/app-services';
import { PaymentDocumentService } from 'app/app-services/payment-document.service';
import { ExtendedPaymentDocument, FilterQuery, PaymentDocument } from 'app/models/payment-document.model';
import { EntityList } from 'app/models/entity-list.model';
import { ReplaySubject, Subject, combineLatest, of, startWith, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'app-admin-payment-document-log',
  templateUrl: './admin-payment-document-log.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminPaymentDocumentLogComponent implements OnInit, OnDestroy {
  paymentsDocument$ = new ReplaySubject<EntityList<ExtendedPaymentDocument>>(1);
  searchedDates = { endDate: null, startDate: null };
  offset$ = of(0);
  private readonly unsubscribeSubject: Subject<void>;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly paymentDocumentService: PaymentDocumentService,
    private readonly handleErrors: HandleErrorsService,
    private readonly router: Router,
  ) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnInit(): void {
    combineLatest([this.route.params, this.route.queryParams, this.paymentDocumentService.update.pipe(startWith(null))])
      .pipe(
        switchMap(([params, queryParams]) => {
          const query = {};

          if (params['page']) {
            query['offset'] = (+params['page'] - 1) * 10;
            this.offset$ = of(+params['page'] - 1);
          }
          Object.keys(queryParams).map(k => {
            switch (k) {
              default:
                query[k] = queryParams[k];
                this.searchedDates[k] = queryParams[k];
                break;
            }
          });

          return this.paymentDocumentService.getAdminPaymentDocumentList(query);
        }),
        takeUntil(this.unsubscribeSubject),
      )
      .subscribe({
        next: response => {
          this.paymentsDocument$.next(response.data);
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
  }
  pageNavigation(event: { count: number; pageSize: number; limit: number; offset: number }) {
    const queryParams = this.route.snapshot.queryParams;
    this.router.navigate(['/admin-payment-document-history', event.offset + 1], { queryParams });
  }

  filterEPayment(queryParams: FilterQuery) {
    this.router.navigate(['admin-payment-document-history'], { queryParams });
  }

  onCheckout(paymentDocument: PaymentDocument) {
    this.router.navigate(['/', { outlets: { dialog: ['confirm-payment', paymentDocument.id] } }]);
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
