import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HandleErrorsService, MagicTranslateService } from 'app/app-services';
import { EntityList, Messages } from 'app/models/entity-list.model';
import { ExtendedPaymentDocument, PaymentDocument } from 'app/models/payment-document.model';

@Component({
  selector: 'admin-payment-document-log-list',
  templateUrl: './admin-payment-document-log-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminPaymentDocumentLogListComponent implements OnChanges, OnInit {
  @Input() paymentsDocumentList: EntityList<ExtendedPaymentDocument>;
  @Input() offset: number;
  @Output() submitFilterOptions = new EventEmitter();
  @Output() checkout = new EventEmitter<PaymentDocument>();
  queryParams: Params;
  messages$: Messages;
  constructor(
    private readonly route: ActivatedRoute,
    protected dialog: MatDialog,
    protected router: Router,
    protected readonly handleErrors: HandleErrorsService,
    private readonly magicTranslateService: MagicTranslateService,
  ) {}

  ngOnChanges() {
    this.queryParams = this.route.snapshot.queryParams;
  }
  ngOnInit() {
    this.messages$ = this.magicTranslateService.getMessage();
  }

  handlePages(event: { count: number; pageSize: number; limit: number; offset: number }) {
    this.submitFilterOptions.emit(event);
  }

  async onCheckOut(paymentDocument: PaymentDocument) {
    this.checkout.emit(paymentDocument);
  }
}
