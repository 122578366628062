import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DeletePrintableDataDialogComponent } from 'app/delete-printable-data-dialog/delete-printable-data-dialog.component';
import { EntityList } from 'app/models/entity-list.model';
import { ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { HandleErrorsService, ModelService, PrintableDataService } from '../app-services';
import { Brand } from '../models/brand.model';
import { ExtendedPrintableData, Model } from '../models/model.model';
import { Serie } from '../models/serie.model';

@Component({
  selector: 'printable-data-management',
  templateUrl: './printable-data-management.component.html',
  styleUrls: ['./printable-data-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintableDataManagementComponent implements OnInit, OnDestroy {
  printableDataList = new ReplaySubject<EntityList<ExtendedPrintableData>>(1);
  modelAutoCompleteList = new ReplaySubject<Model[]>(1);
  brandAutoCompleteList = new ReplaySubject<Brand[]>(1);
  serieAutoCompleteList = new ReplaySubject<Serie[]>(1);
  searchTerms = { brandName: null, serieName: null, modelName: null, type: null };
  offset$ = of(0);

  private readonly unsubscribeSubject: Subject<void>;
  constructor(
    protected modelService: ModelService,
    protected printableDataService: PrintableDataService,
    protected route: ActivatedRoute,
    protected snackBar: MatSnackBar,
    protected router: Router,
    protected handleErrors: HandleErrorsService,
    protected dialog: MatDialog,
  ) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParams, this.printableDataService.update.pipe(startWith(null))])
      .pipe(
        switchMap(([params, queryParams]) => {
          const query = {
            entities: true,
            preview: true,
            limit: 10,
            offset: 0,
          };

          if (!Object.keys(queryParams).length) {
            query['showParents'] = true;
          } else if (queryParams['exactSearch']) {
            query['exactSearch'] = queryParams['exactSearch'];
          }

          if (params['page']) {
            query['offset'] = (+params['page'] - 1) * 10;
            this.offset$ = of(+params['page'] - 1);
          }

          Object.keys(queryParams).map(k => {
            query[k] = queryParams[k];
            this.searchTerms[k] = queryParams[k];
          });

          return this.printableDataService.getPrintableDataList(query);
        }),
        takeUntil(this.unsubscribeSubject),
      )
      .subscribe({
        next: res => {
          this.printableDataList.next(res.data);
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
  }

  fetchLists(event: { type: string; name: string }) {
    const query = {};
    switch (event.type) {
      case 'brandName':
        event.name ? (query['brandName'] = event.name) : query;
        this.modelService
          .getBrandList(query)
          .pipe(distinctUntilChanged())
          .subscribe({
            next: response => {
              this.brandAutoCompleteList.next(response.data.listing);
            },
            error: err => {
              this.handleErrors.handleErrors(err);
            },
          });
        break;
      case 'serieName':
        event.name ? (query['serieName'] = event.name) : query;
        this.modelService
          .getSerieList(query)
          .pipe(distinctUntilChanged())
          .subscribe({
            next: response => {
              this.serieAutoCompleteList.next(response.data.listing);
            },
            error: err => {
              this.handleErrors.handleErrors(err);
            },
          });
        break;
      case 'modelName':
        event.name ? (query['modelName'] = event.name) : query;
        this.modelService
          .getModelList(query)
          .pipe(distinctUntilChanged())
          .subscribe({
            next: response => {
              this.modelAutoCompleteList.next(response.data.listing);
            },
            error: err => {
              this.handleErrors.handleErrors(err);
            },
          });
        break;
    }
  }

  filterPrintableData(form: { brandName: string; serieName: string; modelName: string; type: string }) {
    const query = {};
    Object.keys(form)
      .filter(f => form[f])
      .map(k => {
        query[k] = form[k];
      });
    if (form.type && !form.brandName && !form.modelName && !form.serieName) {
      query['showParents'] = true;
    }

    this.router.navigate(['/printable-data-management'], { queryParams: query });
  }

  pageNavigation(event: { count: number; pageSize: number; limit: number; offset: number }) {
    const queryParams = this.route.snapshot.queryParams;
    this.router.navigate(['/printable-data-management', event.offset + 1], { queryParams });
  }

  openDeletePrintableDataDialog(printableDataId: number) {
    const config: MatDialogConfig = { data: printableDataId, width: '60%', disableClose: true };
    this.dialog.open(DeletePrintableDataDialogComponent, config);
  }

  navigate(printableDataId: number) {
    const queryParams = this.route.snapshot.queryParams;
    const link = printableDataId
      ? ['/', { outlets: { dialog: ['printable-data-preview', printableDataId] } }]
      : ['/', { outlets: { dialog: ['add-printable-data'] } }];
    this.router.navigate(link, { queryParams });
  }
}
