import { Subject } from 'rxjs';

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { Model } from '../models/model.model';
import { User } from '../models/user.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { omitBy, isEmpty } from 'lodash';
import { FilterQuery } from 'app/models/printing.model';

@Component({
  selector: 'admin-printing-filter',
  templateUrl: './admin-printing-filter.component.html',
  styleUrls: ['./admin-printing-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminPrintingFilterComponent implements OnChanges, OnInit, OnDestroy, OnInit {
  @Input() userList: User[];
  @Input() modelList: Model[];
  @Input() searchedDates: { startDate: Date; endDate: Date };
  @Input() searchedUsers: User[];
  @Input() searchedModels: Model[];
  @Output() printingsFilter = new EventEmitter<FilterQuery>();
  @Output() queryModels = new EventEmitter<{ modelName: string; exclude: number[] }>();
  @Output() queryUsers = new EventEmitter<{ like: string; exclude: number[] }>();

  form: FormGroup;

  maxFirstDate = new Date();
  minLastDate: Date = null;
  maxLastDate = new Date();
  private readonly unsubscribeSubject: Subject<void>;
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  @ViewChild('modelInput') modelInput: ElementRef<HTMLInputElement>;

  constructor() {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.form) {
      this.buildForm();
    }

    if (!isEmpty(changes['searchedModels']?.currentValue)) {
      this.form.get('models').setValue(this.searchedModels);
      this.searchedModels = null;
    }

    if (!isEmpty(changes['searchedUsers']?.currentValue)) {
      this.form.get('users').setValue(this.searchedUsers);
      this.searchedUsers = null;
    }

    if (!isEmpty(changes['searchedDates']?.currentValue)) {
      this.form.get('endDate').setValue(this.searchedDates['endDate'] ? new Date(+this.searchedDates['endDate']) : null);
      this.form.get('startDate').setValue(this.searchedDates['startDate'] ? new Date(+this.searchedDates['startDate']) : null);
    }
  }

  ngOnInit() {
    this.form
      .get('userSearch')
      .valueChanges.pipe(
        takeUntil(this.unsubscribeSubject),
        debounceTime(400),
        distinctUntilChanged(),
        filter(user => user?.length > 2),
      )
      .subscribe(userSearched => {
        this.getUsers(userSearched);
      });

    this.form
      .get('modelSearch')
      .valueChanges.pipe(
        takeUntil(this.unsubscribeSubject),
        debounceTime(400),
        distinctUntilChanged(),
        filter(model => model?.length > 2),
      )
      .subscribe(modelSearched => {
        this.getModels(modelSearched);
      });

    if (this.form.controls['startDate'].value != null) {
      this.minLastDate = this.form.controls['startDate'].value;
    }

    if (this.form.controls['endDate'].value != null) {
      this.maxFirstDate = this.form.controls['endDate'].value;
    }

    this.form.controls['startDate'].valueChanges.pipe(takeUntil(this.unsubscribeSubject)).subscribe({
      next: fDate => {
        this.minLastDate = fDate;
      },
    });
    this.form.controls['endDate'].valueChanges.pipe(takeUntil(this.unsubscribeSubject)).subscribe({
      next: lDate => {
        this.maxFirstDate = lDate;
      },
    });
  }

  private buildForm() {
    this.form = new FormGroup({
      users: new FormControl(null),
      userSearch: new FormControl(null),
      models: new FormControl(null),
      modelSearch: new FormControl(null),
      startDate: new FormControl(null),
      endDate: new FormControl(null),
    });
  }

  getModels(modelName: string) {
    const models = this.form.controls['models'].value || [];
    this.queryModels.emit(
      omitBy(
        {
          modelName: modelName,
          exclude: models.map(m => m.id),
        },
        isEmpty,
      ),
    );
  }

  getUsers(userName: string) {
    const users = this.form.controls['users'].value || [];
    this.queryUsers.emit(
      omitBy(
        {
          like: userName,
          exclude: users.map(m => m.id),
        },
        isEmpty,
      ),
    );
  }

  search() {
    const users = this.form.controls['users'].value || [];
    const models = this.form.controls['models'].value || [];
    const startDate = this.form.controls['startDate'].value;
    const endDate = this.form.controls['endDate'].value;

    this.printingsFilter.emit({
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
      modelIds: models.length ? models.map(a => a.id).join(',') : null,
      userIds: users.length ? users.map(a => a.id).join(',') : null,
    });
  }
  removeUser(user: User): void {
    const users: User[] = this.form.get('users').value;
    this.form.get('users').setValue(users.filter(item => item.id !== user.id));
  }

  selectedUser(event: MatAutocompleteSelectedEvent): void {
    const value: User[] = this.form.get('users').value ?? [];
    this.form.get('users').setValue([...value, event.option.value]);

    this.userInput.nativeElement.value = '';
    this.form.get('userSearch').setValue(null);
    this.userList = undefined;
  }
  removeModel(model: Model): void {
    const models: Model[] = this.form.get('models').value;
    this.form.get('models').setValue(models.filter(item => item.id !== model.id));
  }

  selectedModel(event: MatAutocompleteSelectedEvent): void {
    const value: Model[] = this.form.get('models').value ?? [];
    this.form.get('models').setValue([...value, event.option.value]);

    this.modelInput.nativeElement.value = '';
    this.form.get('modelSearch').setValue(null);
    this.modelList = undefined;
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
