import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Admin } from 'app/models/admin.model';
import { FilterQuery } from 'app/models/loggin.model';
import { PaymentDocumentStatus, PaymentDocumentType } from 'app/models/payment-document.model';
import { omitBy, isEmpty } from 'lodash';
import { Subject, debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'super-admin-payment-document-log-filter',
  templateUrl: './super-admin-payment-document-log-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EPaymentFilterComponent implements OnDestroy, OnChanges {
  paymentDocumentFiltreForm: FormGroup;
  @Input() searchedDates: { startDate: Date; endDate: Date };
  @Input() admins: Admin[];
  @Input() searchedAdmins: Admin[];
  @Input() SearchedStatus: PaymentDocumentStatus;
  @Input() SearchedType: PaymentDocumentType;
  @Output() filterQuery = new EventEmitter<FilterQuery>();
  @Output() queryAdmins = new EventEmitter<{ like: string; exclude: number[] }>();

  @Output() addPaymentDocument = new EventEmitter<void>();
  maxFirstDate = new Date();
  minLastDate: Date = null;
  maxLastDate = new Date();
  type = null;
  status = null;
  paymentDocumentTypes = Object.values(PaymentDocumentType);
  paymentDocumentStatus = Object.values(PaymentDocumentStatus);

  @ViewChild('adminInput') adminInput: ElementRef<HTMLInputElement>;
  private readonly unsubscribeSubject: Subject<void>;

  constructor(private fb: FormBuilder) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.paymentDocumentFiltreForm) {
      this.buildForm();
    }

    if (!isEmpty(changes['searchedAdmins']?.currentValue)) {
      this.paymentDocumentFiltreForm.get('admins').setValue(this.searchedAdmins);
      this.searchedAdmins = null;
    }

    if (!isEmpty(changes['searchedDates']?.currentValue)) {
      this.paymentDocumentFiltreForm
        .get('endDate')
        .setValue(this.searchedDates['endDate'] ? new Date(+this.searchedDates['endDate']) : null);
      this.paymentDocumentFiltreForm
        .get('startDate')
        .setValue(this.searchedDates['startDate'] ? new Date(+this.searchedDates['startDate']) : null);
    }

    if (!isEmpty(changes['SearchedType']?.currentValue)) {
      this.paymentDocumentFiltreForm.get('type').setValue(this.SearchedType);
      this.SearchedType = null;
    }

    if (!isEmpty(changes['SearchedStatus']?.currentValue)) {
      this.paymentDocumentFiltreForm.get('status').setValue(this.SearchedType);
      this.SearchedType = null;
    }
  }

  private buildForm() {
    this.paymentDocumentFiltreForm = this.fb.group({
      admins: '',
      adminSearch: '',
      startDate: '',
      endDate: '',
      type: '',
      status: '',
    });

    this.paymentDocumentFiltreForm
      .get('adminSearch')
      .valueChanges.pipe(
        takeUntil(this.unsubscribeSubject),
        debounceTime(400),
        distinctUntilChanged(),
        filter(admin => admin?.length > 2),
      )
      .subscribe(adminSearch => {
        this.getAdmins(adminSearch);
      });

    this.paymentDocumentFiltreForm.controls['startDate'].valueChanges.pipe(takeUntil(this.unsubscribeSubject)).subscribe(fDate => {
      this.minLastDate = fDate;
    });
    this.paymentDocumentFiltreForm.controls['endDate'].valueChanges.pipe(takeUntil(this.unsubscribeSubject)).subscribe(lDate => {
      this.maxFirstDate = lDate;
    });

    this.paymentDocumentFiltreForm.controls['type'].valueChanges.pipe(takeUntil(this.unsubscribeSubject)).subscribe({
      next: lType => {
        this.type = lType;
      },
    });

    this.paymentDocumentFiltreForm.controls['status'].valueChanges.pipe(takeUntil(this.unsubscribeSubject)).subscribe({
      next: lStatus => {
        this.status = lStatus;
      },
    });
  }

  getAdmins(adminName: string) {
    const admins = this.paymentDocumentFiltreForm.controls['admins'].value || [];
    this.queryAdmins.emit(
      omitBy(
        {
          like: adminName,
          exclude: admins.map(m => m.id),
        },
        isEmpty,
      ),
    );
  }

  search(form: { admins: { id: number; value: string }[] }) {
    const admins = form.admins && form.admins.length > 0 ? form.admins.map(a => a.id).join(',') : null;
    const startDate = this.paymentDocumentFiltreForm.controls['startDate'].value;
    const endDate = this.paymentDocumentFiltreForm.controls['endDate'].value;
    const type = this.paymentDocumentFiltreForm.controls['type'].value;
    const status = this.paymentDocumentFiltreForm.controls['status'].value;
    this.filterQuery.emit({
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
      adminIds: admins,
      type: type ? type : null,
      status: status ? status : null,
    });
  }

  remove(admin: Admin): void {
    const admins: Admin[] = this.paymentDocumentFiltreForm.get('admins').value;
    this.paymentDocumentFiltreForm.get('admins').setValue(admins.filter(item => item.id !== admin.id));
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value: Admin[] = this.paymentDocumentFiltreForm.get('admins').value ?? [];
    this.paymentDocumentFiltreForm.get('admins').setValue([...value, event.option.value]);

    this.adminInput.nativeElement.value = '';
    this.paymentDocumentFiltreForm.get('adminSearch').setValue(null);
    this.admins = undefined;
  }

  onAddAdmin() {
    this.addPaymentDocument.emit();
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
