import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

import { EntityList, Messages } from '../models/entity-list.model';
import { User } from '../models/user.model';
import { MagicTranslateService } from 'app/app-services';

@Component({
  selector: 'admin-users-list',
  templateUrl: './admin-users-list.component.html',
  styleUrls: ['./admin-users-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminUsersListComponent implements AfterViewChecked, OnInit {
  @Input() users: EntityList<User>;
  @Input() isAdmin: boolean;
  @Input() offset: number;

  @Output() toggleActivation = new EventEmitter();
  @Output() submitFilterOptions = new EventEmitter();
  @ViewChild('dataTable') dataTable: DatatableComponent;

  messages$: Messages;

  constructor(
    private cd: ChangeDetectorRef,
    private readonly magicTranslateService: MagicTranslateService,
  ) {}

  ngOnInit() {
    this.messages$ = this.magicTranslateService.getMessage();
  }
  ngAfterViewChecked() {
    if (this.dataTable) {
      this.dataTable.recalculate();
      this.cd.detectChanges();
    }
  }

  changeStatus(userId: number, event: any) {
    this.toggleActivation.emit({ userId: userId, isActive: event.checked });
  }

  handlePages(event: { count: number; pageSize: number; limit: number; offset: number }) {
    this.submitFilterOptions.emit(event);
    this.offset = event.offset;
  }
}
