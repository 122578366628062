import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService, HandleErrorsService } from 'app/app-services';
import { AdminQuery } from 'app/app-services/admin.service';
import { Admin } from 'app/models/admin.model';
import { EntityList } from 'app/models/entity-list.model';
import { EMPTY, Observable, ReplaySubject, Subject, catchError, combineLatest, map, of, startWith, switchMap, takeUntil } from 'rxjs';
import { ExtendedPaymentDocument, FilterQuery } from 'app/models/payment-document.model';
import { PaymentDocumentService } from 'app/app-services/payment-document.service';

@Component({
  selector: 'super-admin-payment-document-log',
  templateUrl: './super-admin-payment-document-log.component.html',
})
export class SuperAdminPaymentDocumentLogComponent implements OnInit, OnDestroy {
  private readonly unsubscribeSubject: Subject<void>;
  paymentsDocumentList = new ReplaySubject<EntityList<ExtendedPaymentDocument>>(1);
  adminList = new ReplaySubject<Admin[]>(1);

  searchedAdmins$: Observable<Admin[]>;

  searchedDates = { endDate: null, startDate: null };
  offset$ = of(0);
  constructor(
    private readonly adminService: AdminService,
    private readonly PaymentDocumentService: PaymentDocumentService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly handleErrors: HandleErrorsService,
  ) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParams, this.PaymentDocumentService.update.pipe(startWith(null))])
      .pipe(
        switchMap(([params, queryParams]) => {
          const query = {};

          if (params['page']) {
            query['offset'] = (+params['page'] - 1) * 10;
            this.offset$ = of(+params['page'] - 1);
          }
          Object.keys(queryParams).map(k => {
            switch (k) {
              case 'adminIds':
                query['adminIds'] = queryParams['adminIds'].split(',');

                this.searchedAdmins$ = this.adminService.getAdminList({ ids: query['adminIds'] }).pipe(
                  map(result => result.data.listing),
                  catchError(error => {
                    this.handleErrors.handleErrors(error);

                    return EMPTY;
                  }),
                );

                break;

              default:
                query[k] = queryParams[k];
                this.searchedDates[k] = queryParams[k];
                break;
            }
          });

          return this.PaymentDocumentService.getPaymentDocumentList(query);
        }),
        takeUntil(this.unsubscribeSubject),
      )
      .subscribe({
        next: response => {
          this.paymentsDocumentList.next(response.data);
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
  }

  pageNavigation(event: { count: number; pageSize: number; limit: number; offset: number }) {
    const queryParams = this.route.snapshot.queryParams;
    this.router.navigate(['/super-admin-payment-history', event.offset + 1], { queryParams });
  }

  filterEPayment(queryParams: FilterQuery) {
    this.router.navigate(['super-admin-payment-history'], { queryParams });
  }

  fetchAdmins(query: AdminQuery) {
    this.adminService.getAdminList(query).subscribe({
      next: response => {
        this.adminList.next(response.data.listing);
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  onAddPaymentDocumentClick() {
    this.router.navigate(['/', { outlets: { dialog: ['add-payment-document'] } }], { queryParams: this.route.snapshot.queryParams });
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
