import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, from, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AvailableLanguagesEnum } from '../constants/available-languages.enum';
import { Messages } from 'app/models/entity-list.model';

@Injectable()
export class MagicTranslateService {
  constructor(private translateService: TranslateService) {}

  initLanguage(): Observable<any> {
    const storedLang = localStorage.getItem('Language') as AvailableLanguagesEnum;
    const defaultLang = storedLang || (this.translateService.getBrowserLang() as AvailableLanguagesEnum) || AvailableLanguagesEnum.EN;

    this.translateService.setDefaultLang(defaultLang);

    return this.changeUserLanguage(defaultLang);
  }

  updateUserLanguage(language: AvailableLanguagesEnum) {
    localStorage.setItem('Language', language);
    this.changeUserLanguage(language);
  }

  changeUserLanguage(localeId: AvailableLanguagesEnum) {
    return this.translateService.use(localeId).pipe(
      switchMap(() => from(this.getLocale(localeId))),
      tap(module => {
        registerLocaleData(module.default);
      }),
    );
  }

  async getLocale(localeId: string): Promise<any> {
    return import(
      /* webpackInclude: /(fr|ar|es|en)\.mjs/ */
      /* webpackExclude: /\.d\.ts$/ */
      /* webpackMode: "lazy-once" */
      /* webpackChunkName: "i18n" */
      `/node_modules/@angular/common/locales/${localeId}`
    );
  }

  getMessage(): Messages {
    return forkJoin({ emptyMessage: this.translateService.get('no-data'), totalMessage: this.translateService.get('total') });
  }
}
