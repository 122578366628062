import { Subject } from 'rxjs';

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil, filter } from 'rxjs/operators';
import { Admin } from '../models/admin.model';
import { Model } from '../models/model.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { omitBy, isEmpty } from 'lodash';
import { FilterQuery } from 'app/models/printing.model';

@Component({
  selector: 'super-admin-printing-filter',
  templateUrl: './super-admin-printing-filter.component.html',
  styleUrls: ['./super-admin-printing-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuperAdminPrintingFilterComponent implements OnChanges, OnInit, OnDestroy {
  printingFilterForm: FormGroup;
  @Input() adminList: Admin[];
  @Input() models: Model[];
  @Input() searchedDates: { startDate: Date; endDate: Date };
  @Input() searchedAdmins: Admin[];
  @Input() searchedModels: Model[];
  @Output() filterQuery = new EventEmitter<FilterQuery>();
  @Output() queryModels = new EventEmitter<{ modelName: string; exclude: number[] }>();
  @Output() queryAdmins = new EventEmitter<{ like: string; exclude: number[] }>();
  maxFirstDate = new Date();
  minLastDate: Date = null;
  maxLastDate = new Date();
  @ViewChild('adminInput') adminInput: ElementRef<HTMLInputElement>;
  @ViewChild('modelInput') modelInput: ElementRef<HTMLInputElement>;
  private readonly unsubscribeSubject: Subject<void>;

  constructor() {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.printingFilterForm) {
      this.buildForm();
    }

    if (!isEmpty(changes['searchedModels']?.currentValue)) {
      this.printingFilterForm.get('models').setValue(this.searchedModels);
      this.searchedModels = null;
    }

    if (!isEmpty(changes['searchedAdmins']?.currentValue)) {
      this.printingFilterForm.get('admins').setValue(this.searchedAdmins);
      this.searchedAdmins = null;
    }

    if (!isEmpty(changes['searchedDates']?.currentValue)) {
      this.printingFilterForm.get('endDate').setValue(this.searchedDates['endDate'] ? new Date(+this.searchedDates['endDate']) : null);
      this.printingFilterForm
        .get('startDate')
        .setValue(this.searchedDates['startDate'] ? new Date(+this.searchedDates['startDate']) : null);
    }
  }

  ngOnInit() {
    this.printingFilterForm
      .get('adminSearch')
      .valueChanges.pipe(
        takeUntil(this.unsubscribeSubject),
        debounceTime(400),
        distinctUntilChanged(),
        filter(admin => admin?.length > 2),
      )
      .subscribe(adminSearch => {
        this.getAdmins(adminSearch);
      });

    this.printingFilterForm
      .get('modelSearch')
      .valueChanges.pipe(
        takeUntil(this.unsubscribeSubject),
        debounceTime(400),
        distinctUntilChanged(),
        filter(model => model?.length > 2),
      )
      .subscribe(modelSearch => {
        this.getModels(modelSearch);
      });

    if (this.printingFilterForm.controls['startDate'].value != null) {
      this.minLastDate = this.printingFilterForm.controls['startDate'].value;
    }

    if (this.printingFilterForm.controls['endDate'].value != null) {
      this.maxFirstDate = this.printingFilterForm.controls['endDate'].value;
    }

    this.printingFilterForm.controls['startDate'].valueChanges
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(fDate => (this.minLastDate = fDate));
    this.printingFilterForm.controls['endDate'].valueChanges
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(lDate => (this.maxFirstDate = lDate));
  }

  getModels(modelName: string) {
    const models = this.printingFilterForm.controls['models'].value || [];
    this.queryModels.emit(
      omitBy(
        {
          modelName: modelName,
          exclude: models.map(m => m.id),
        },
        isEmpty,
      ),
    );
  }

  getAdmins(adminName: string) {
    const admins = this.printingFilterForm.controls['admins'].value || [];
    this.queryAdmins.emit(
      omitBy(
        {
          like: adminName,
          exclude: admins.map(m => m.id),
        },
        isEmpty,
      ),
    );
  }

  search() {
    const admins = this.printingFilterForm.controls['admins'].value;
    const models = this.printingFilterForm.controls['models'].value;
    const startDate = this.printingFilterForm.controls['startDate'].value;
    const endDate = this.printingFilterForm.controls['endDate'].value;

    this.filterQuery.emit({
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
      modelIds: models && models.length ? models.map(a => a.id).join(',') : null,
      adminIds: admins && admins.length ? admins.map(a => a.id).join(',') : null,
    });
  }

  private buildForm() {
    this.printingFilterForm = new FormGroup({
      admins: new FormControl(null),
      models: new FormControl(null),
      adminSearch: new FormControl(null),
      modelSearch: new FormControl(null),
      startDate: new FormControl(null),
      endDate: new FormControl(null),
    });
  }

  removeAdmin(admin: Admin): void {
    const admins: Admin[] = this.printingFilterForm.get('admins').value;
    this.printingFilterForm.get('admins').setValue(admins.filter(item => item.id !== admin.id));
  }

  selectedAdmin(event: MatAutocompleteSelectedEvent): void {
    const value: Admin[] = this.printingFilterForm.get('admins').value ?? [];
    this.printingFilterForm.get('admins').setValue([...value, event.option.value]);

    this.adminInput.nativeElement.value = '';
    this.printingFilterForm.get('adminSearch').setValue(null);
    this.adminList = undefined;
  }

  removeModel(model: Model): void {
    const models: Model[] = this.printingFilterForm.get('models').value;
    this.printingFilterForm.get('models').setValue(models.filter(item => item.id !== model.id));
  }

  selectedModel(event: MatAutocompleteSelectedEvent): void {
    const value: Model[] = this.printingFilterForm.get('models').value ?? [];
    this.printingFilterForm.get('models').setValue([...value, event.option.value]);

    this.modelInput.nativeElement.value = '';
    this.printingFilterForm.get('modelSearch').setValue(null);
    this.models = undefined;
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
