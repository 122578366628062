import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FilterQuery, PaymentDocumentStatus, PaymentDocumentType } from 'app/models/payment-document.model';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'admin-payment-document-log-filter',
  templateUrl: './admin-payment-document-log-filter.component.html',
  styleUrls: ['./admin-payment-document-log-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminPaymentDocumentLogFilterComponent implements OnChanges {
  paymentDocumentFiltreForm: FormGroup;
  @Input() searchedDates: { startDate: Date; endDate: Date };
  @Input() SearchedStatus: PaymentDocumentStatus;
  @Input() SearchedType: PaymentDocumentType;
  @Output() filterQuery = new EventEmitter<FilterQuery>();
  @Output() queryAdmins = new EventEmitter<{ like: string; exclude: number[] }>();

  @Output() addPaymentDocument = new EventEmitter<void>();

  maxFirstDate = new Date();
  minLastDate: Date = null;
  maxLastDate = new Date();
  type = null;
  status = null;
  paymentDocumentTypes = Object.values(PaymentDocumentType);
  paymentDocumentStatus = Object.values(PaymentDocumentStatus);
  private readonly unsubscribeSubject: Subject<void>;
  constructor() {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.paymentDocumentFiltreForm) {
      this.buildForm();
    }

    if (changes['searchedDates']?.currentValue) {
      this.paymentDocumentFiltreForm
        .get('endDate')
        .setValue(this.searchedDates['endDate'] ? new Date(+this.searchedDates['endDate']) : null);
      this.paymentDocumentFiltreForm
        .get('startDate')
        .setValue(this.searchedDates['startDate'] ? new Date(+this.searchedDates['startDate']) : null);
    }

    if (changes['SearchedType']?.currentValue) {
      this.paymentDocumentFiltreForm.get('type').setValue(this.SearchedType);
      this.SearchedType = null;
    }

    if (changes['SearchedStatus']?.currentValue) {
      this.paymentDocumentFiltreForm.get('status').setValue(this.SearchedType);
      this.SearchedType = null;
    }
  }

  private buildForm() {
    this.paymentDocumentFiltreForm = new FormGroup({
      startDate: new FormControl(null),
      endDate: new FormControl(null),
      type: new FormControl(null),
      status: new FormControl(null),
    });

    this.paymentDocumentFiltreForm.controls['startDate'].valueChanges.pipe(takeUntil(this.unsubscribeSubject)).subscribe(startDate => {
      this.minLastDate = startDate;
    });
    this.paymentDocumentFiltreForm.controls['endDate'].valueChanges.pipe(takeUntil(this.unsubscribeSubject)).subscribe(endDate => {
      this.maxFirstDate = endDate;
    });
    this.paymentDocumentFiltreForm.controls['type'].valueChanges.pipe(takeUntil(this.unsubscribeSubject)).subscribe({
      next: lType => {
        this.type = lType;
      },
    });

    this.paymentDocumentFiltreForm.controls['status'].valueChanges.pipe(takeUntil(this.unsubscribeSubject)).subscribe({
      next: lStatus => {
        this.status = lStatus;
      },
    });
  }

  search() {
    const startDate = this.paymentDocumentFiltreForm.controls['startDate'].value;
    const endDate = this.paymentDocumentFiltreForm.controls['endDate'].value;
    const type = this.paymentDocumentFiltreForm.controls['type'].value;
    const status = this.paymentDocumentFiltreForm.controls['status'].value;
    this.filterQuery.emit({
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
      type: type ? type : null,
      status: status ? status : null,
    });
  }
}
