import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ExtendedLogging } from 'app/models/loggin.model';
import { EntityList, Messages } from 'app/models/entity-list.model';
import { ActivatedRoute, Params } from '@angular/router';
import { MagicTranslateService } from 'app/app-services';

@Component({
  selector: 'admin-credit-log-list',
  templateUrl: './admin-credit-log-list.component.html',
})
export class AdminCreditLogListComponent implements OnChanges, OnInit {
  @Input() loggings: EntityList<ExtendedLogging>;
  @Input() offset: number;

  @Output() submitFilterOptions = new EventEmitter();
  queryParams: Params;
  messages$: Messages;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly magicTranslateService: MagicTranslateService,
  ) {}

  ngOnChanges() {
    this.queryParams = this.route.snapshot.queryParams;
  }
  ngOnInit() {
    this.messages$ = this.magicTranslateService.getMessage();
  }

  handlePages(event: { count: number; pageSize: number; limit: number; offset: number }) {
    this.submitFilterOptions.emit(event);
  }
}
