import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { isEmpty, omitBy } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import { User } from '../models/user.model';

@Component({
  selector: 'admin-users-filter',
  templateUrl: './admin-users-filter.component.html',
  styleUrls: ['./admin-users-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminUsersFilterComponent implements OnChanges, OnInit {
  @Input() users: User[];
  @Input() isAdmin: boolean;
  @Input() searchedUsers: User[];

  @Output() filterUsers = new EventEmitter();
  @Output() queryUsers = new EventEmitter<{ like: string; exclude: number[] }>();
  @Output() addNewUser = new EventEmitter<void>();
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;

  userFilterForm: FormGroup;

  private readonly unsubscribeSubject: Subject<void>;

  constructor() {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.userFilterForm) {
      this.buildForm();
    }

    if (changes['searchedUsers'] && changes['searchedUsers'].previousValue === null && changes['searchedUsers'].currentValue) {
      this.userFilterForm.get('users').setValue(this.searchedUsers);
      this.searchedUsers = null;
    }
  }

  ngOnInit() {
    this.userFilterForm
      .get('userSearch')
      .valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        takeUntil(this.unsubscribeSubject),
        filter(userSearch => userSearch?.length > 2),
      )
      .subscribe(userSearch => {
        this.getUsers(userSearch);
      });
  }
  private buildForm() {
    this.userFilterForm = new FormGroup({
      users: new FormControl(null),
      userSearch: new FormControl(null),
    });
  }

  getUsers(userName: string) {
    const users = this.userFilterForm.controls['users'].value || [];
    this.queryUsers.emit(
      omitBy(
        {
          like: userName,
          exclude: users.map(m => m.id),
        },
        isEmpty,
      ),
    );
  }

  search(form: { users: User[] }) {
    this.filterUsers.emit(form.users && form.users.length ? form.users.map(a => a.id).join(',') : null);
  }

  onAddNewUser() {
    this.addNewUser.emit();
  }
  remove(user: User): void {
    const users: User[] = this.userFilterForm.get('users').value;
    this.userFilterForm.get('users').setValue(users.filter(item => item.id !== user.id));
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value: User[] = this.userFilterForm.get('users').value ?? [];
    this.userFilterForm.get('users').setValue([...value, event.option.value]);

    this.userInput.nativeElement.value = '';
    this.userFilterForm.get('userSearch').setValue(null);
    this.users = undefined;
  }
}
