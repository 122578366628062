import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

@Injectable()
export class FormDataInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get('content-type') === 'multipart/form-data') {
      let headers = req.headers;
      headers = headers.delete('Content-Type');
      let newReq = req.clone({ headers: headers });
      newReq = newReq.clone({ body: this.toFormData(req.body) });

      return next.handle(newReq);
    } else {
      return next.handle(req);
    }
  }
  toFormData(raw: unknown) {
    const leafs = paths(raw, '');
    // tslint:disable-next-line:prefer-const
    const files: File[] = [];
    const associations = [];
    leafs.map(f => {
      const fi = _.at(raw, f)[0];
      if (fi instanceof File) {
        const i = files.push(fi) - 1;
        _.set(raw, f, '');
        associations.push({ path: f, key: i });
      }
    });
    const fd = new FormData();
    fd.append('data', JSON.stringify(raw));
    fd.append('associations', JSON.stringify(associations));
    files.map(f => {
      fd.append('files', f);
    });

    return fd;
  }
}
function paths(obj, parentKey) {
  let result;
  if (_.isArray(obj)) {
    let idx = 0;
    result = _.flatMap(obj, function (r) {
      return paths(r, (parentKey || '') + '[' + idx++ + ']');
    });
  } else if (_.isPlainObject(obj)) {
    result = _.flatMap(_.keys(obj), function (key) {
      return _.map(paths(obj[key], key), function (subkey) {
        return (parentKey ? parentKey + '.' : '') + subkey;
      });
    });
  } else {
    result = [];
  }

  return _.concat(result, parentKey || []);
}
