import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { EntityList, Messages } from 'app/models/entity-list.model';
import { ExtendedPrinting } from '../models/printing.model';
import { MagicTranslateService } from 'app/app-services';

@Component({
  selector: 'printing-list',
  templateUrl: './printing-list.component.html',
  styleUrls: ['./printing-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintingListComponent implements OnChanges, OnInit {
  @Input() printings: EntityList<ExtendedPrinting>;
  @Input() offset: number;
  @Output() tablePagesNavigation = new EventEmitter();
  queryParams: Params;
  messages$: Messages;

  constructor(
    protected route: ActivatedRoute,
    private readonly magicTranslateService: MagicTranslateService,
  ) {}

  ngOnChanges() {
    this.queryParams = this.route.snapshot.queryParams;
  }

  ngOnInit() {
    this.messages$ = this.magicTranslateService.getMessage();
  }

  handlePages(event: { count: number; pageSize: number; limit: number; offset: number }) {
    this.tablePagesNavigation.emit(event);
  }
}
