import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityList } from 'app/models/entity-list.model';
import { EMPTY, Observable, ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { catchError, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { AdminService, HandleErrorsService } from '../app-services';
import { DeleteAdminAndTransferUsersComponent } from '../delete-admin-and-transfer-users/delete-admin-and-transfer-users.component';
import { Admin } from '../models/admin.model';
import { AdminQuery } from 'app/app-services/admin.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'admin-management',
  templateUrl: './admin-management.component.html',
  styleUrls: ['./admin-management.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminManagementComponent implements OnInit, OnDestroy {
  adminList = new ReplaySubject<EntityList<Admin>>(1);
  adminListCompleteList = new ReplaySubject<Admin[]>(1);
  offset$ = of(0);
  searchedAdmins$: Observable<Admin[]>;
  private readonly unsubscribeSubject: Subject<void>;
  constructor(
    protected snackBar: MatSnackBar,
    protected adminService: AdminService,
    protected dialog: MatDialog,
    protected router: Router,
    protected route: ActivatedRoute,
    protected handleErrors: HandleErrorsService,
    protected translateService: TranslateService,
  ) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParams, this.adminService.update.pipe(startWith(null))])
      .pipe(
        switchMap(([params, queryParams]) => {
          const query = {};

          if (queryParams['ids']) {
            query['ids'] = queryParams['ids'].split(',');
            this.searchedAdmins$ = this.adminService.getAdminList({ ids: query['ids'] }).pipe(
              map(result => result.data.listing),
              catchError(error => {
                this.handleErrors.handleErrors(error);

                return EMPTY;
              }),
            );
          }

          if (params['page']) {
            query['offset'] = (+params['page'] - 1) * 10;
            this.offset$ = of(+params['page'] - 1);
          }

          return this.adminService.getAdminList(query);
        }),
        takeUntil(this.unsubscribeSubject),
      )
      .subscribe({
        next: response => {
          this.adminList.next(response.data);
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
  }

  setActivation(event: { adminId: number; isActive: boolean }) {
    this.adminService.setActivation(event.adminId, event.isActive).subscribe({
      next: () => {
        const activation = event.isActive ? 'admin-enabled' : 'admin-disabled';
        this.translateService.get(activation).subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  openDeleteAdminDialog(admin: Admin) {
    const config: MatDialogConfig = { data: admin, width: '60%', disableClose: true };
    this.dialog.open(DeleteAdminAndTransferUsersComponent, config);
  }

  pageNavigation(event: { count: number; pageSize: number; limit: number; offset: number }) {
    const queryParams = this.route.snapshot.queryParams;
    this.router.navigate(['/admin-management', event.offset + 1], { queryParams });
  }

  filterAdmins(ids: number[]) {
    this.router.navigate(['/admin-management/'], { queryParams: { ids } });
  }

  fetchAdmins(query: AdminQuery) {
    this.adminService.getAdminList(query).subscribe({
      next: res => {
        this.adminListCompleteList.next(res.data.listing);
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  onAddAdminClick() {
    this.router.navigate(['/', { outlets: { dialog: ['add-admin'] } }]);
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
