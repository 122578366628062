import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EntityList, Messages } from 'app/models/entity-list.model';
import { User } from '../models/user.model';
import { MagicTranslateService } from 'app/app-services';

@Component({
  selector: 'super-admin-user-list',
  templateUrl: './super-admin-user-list.component.html',
  styleUrls: ['./super-admin-user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuperAdminUserListComponent implements OnInit {
  @Input() userList: EntityList<User>;
  @Input() offset: number;
  @Output() openConfirmationDialog = new EventEmitter();
  @Output() submitFilterOptions = new EventEmitter();

  messages$: Messages;

  constructor(private readonly magicTranslateService: MagicTranslateService) {}

  ngOnInit() {
    this.messages$ = this.magicTranslateService.getMessage();
  }
  deleteUser(user: User) {
    this.openConfirmationDialog.emit(user);
  }

  handlePages(event: { count: number; pageSize: number; limit: number; offset: number }) {
    this.submitFilterOptions.emit(event);
  }
}
