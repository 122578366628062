import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HandleErrorsService, UsersService } from 'app/app-services';
import { LoggingService } from 'app/app-services/logging.service';
import { AdminQuery } from 'app/app-services/admin.service';
import { EntityList } from 'app/models/entity-list.model';
import { ExtendedLogging, FilterQuery } from 'app/models/loggin.model';
import { User } from 'app/models/user.model';
import { EMPTY, Observable, ReplaySubject, Subject, catchError, combineLatest, map, of, switchMap } from 'rxjs';

@Component({
  selector: 'admin-credit-log',
  templateUrl: './admin-credit-log.component.html',
})
export class AdminCreditLogComponent implements OnInit, OnDestroy {
  private readonly unsubscribeSubject: Subject<void>;
  loggings = new ReplaySubject<EntityList<ExtendedLogging>>(1);
  userList = new ReplaySubject<User[]>(1);
  searchedUsers$: Observable<User[]>;

  searchedDatas$: Observable<FilterQuery>;
  offset$ = of(0);
  constructor(
    private readonly userService: UsersService,
    private readonly loggingService: LoggingService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly handleErrors: HandleErrorsService,
  ) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(
        switchMap(([params, queryParams]) => {
          const query = {};

          if (params['page']) {
            query['offset'] = (+params['page'] - 1) * 10;
            this.offset$ = of(+params['page'] - 1);
          }
          Object.keys(queryParams).map(key => {
            if (key === 'userIds') {
              query['userIds'] = queryParams['userIds'].split(',');

              this.searchedUsers$ = this.userService.getUserList({ userIds: query['userIds'] }).pipe(
                map(result => result.data?.listing),
                catchError(error => {
                  this.handleErrors.handleErrors(error);

                  return EMPTY;
                }),
              );
            } else {
              query[key] = queryParams[key];
            }
          });
          this.searchedDatas$ = of(query);

          return this.loggingService.getAdminLoggingList(query);
        }),
      )
      .subscribe({
        next: response => {
          this.loggings.next(response.data);
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
  }

  filterLogging(filterdInfo: FilterQuery) {
    this.router.navigate(['admin-credit-history'], { queryParams: filterdInfo });
  }

  fetchUsers(query: AdminQuery) {
    this.userService.getUserList(query).subscribe({
      next: response => {
        this.userList.next(response.data.listing);
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  pageNavigation(event: { count: number; pageSize: number; limit: number; offset: number }) {
    const queryParams = this.route.snapshot.queryParams;
    this.router.navigate(['/admin-credit-history', event.offset + 1], { queryParams });
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
