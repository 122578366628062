import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { EntityList, Messages } from 'app/models/entity-list.model';
import { ActivatedRoute, Params } from '@angular/router';
import { ExtendedPaymentDocument, PaymentDocument } from 'app/models/payment-document.model';
import { MagicTranslateService } from 'app/app-services';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmCancelUnpaidPaymentDocumentComponent } from 'app/confirm-cancel-unpaid-payment-document/confirm-cancel-unpaid-payment-document.component';

@Component({
  selector: 'super-admin-payment-document-log-list',
  templateUrl: './super-admin-payment-document-log-list.component.html',
})
export class EPaymentListComponent implements OnChanges, OnInit {
  @Input() paymentsDocumentList: EntityList<ExtendedPaymentDocument>;
  @Input() offset: number;

  @Output() submitFilterOptions = new EventEmitter();
  queryParams: Params;
  messages$: Messages;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly magicTranslateService: MagicTranslateService,
    private readonly dialog: MatDialog,
  ) {}

  ngOnChanges() {
    this.queryParams = this.route.snapshot.queryParams;
  }

  ngOnInit() {
    this.messages$ = this.magicTranslateService.getMessage();
  }

  handlePages(event: { count: number; pageSize: number; limit: number; offset: number }) {
    this.submitFilterOptions.emit(event);
  }

  cancel(paymentDocument: PaymentDocument) {
    const config: MatDialogConfig = {
      data: paymentDocument,
      width: '50%',
      disableClose: true,
    };
    this.dialog.open(ConfirmCancelUnpaidPaymentDocumentComponent, config);
  }
}
