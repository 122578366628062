import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UserQuery } from 'app/app-services/users.service';
import { EntityList } from 'app/models/entity-list.model';
import { EMPTY, Observable, ReplaySubject, Subject, combineLatest, of, switchMap } from 'rxjs';
import { catchError, map, startWith, takeUntil } from 'rxjs/operators';
import { HandleErrorsService, UsersService } from '../app-services';
import { User } from '../models/user.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'admin-users-list-tab',
  templateUrl: './admin-users-list-tab.component.html',
  styleUrls: ['./admin-users-list-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminUsersListTabComponent implements OnInit, OnDestroy {
  @Input() currentAdminId: number;
  @Input() isAdmin: boolean;
  adminId: number;
  usersList = new ReplaySubject<EntityList<User>>(1);
  usersAutoCompleteList = new ReplaySubject<User[]>(1);
  offset$ = of(0);
  searchedUsers$: Observable<User[]>;
  private readonly unsubscribeSubject: Subject<void>;
  constructor(
    protected userService: UsersService,
    protected route: ActivatedRoute,
    protected snackBar: MatSnackBar,
    protected router: Router,
    protected handleErrors: HandleErrorsService,
    protected translate: TranslateService,
  ) {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnInit() {
    this.adminId = this.currentAdminId ?? this.route.parent.snapshot.data['adminDetails'].id;

    combineLatest([this.route.params, this.route.queryParams, this.userService.update.pipe(startWith(null))])
      .pipe(
        switchMap(([params, queryParams]) => {
          const query = {};
          if (queryParams['userIds']) {
            query['userIds'] = queryParams['userIds'].split(',');

            this.searchedUsers$ = this.userService.getUserList({ userIds: query['userIds'] }).pipe(
              map(result => result.data?.listing),
              catchError(error => {
                this.handleErrors.handleErrors(error);

                return EMPTY;
              }),
            );
          }

          if (params['page']) {
            query['offset'] = (+params['page'] - 1) * 10;
            this.offset$ = of(+params['page'] - 1);
          }

          if (!this.isAdmin && !queryParams['userIds']) {
            query['adminIds'] = [this.adminId];
          }

          return this.userService.getUserList(query);
        }),
        takeUntil(this.unsubscribeSubject),
      )
      .subscribe({
        next: response => {
          this.usersList.next(response.data);
        },
        error: err => {
          this.handleErrors.handleErrors(err);
        },
      });
  }

  setActivation(event: { userId: number; isActive: boolean }) {
    this.userService.setActivation(event.userId, event.isActive).subscribe({
      next: () => {
        const activation = event.isActive ? 'user-enabled' : 'user-disabled';
        this.translate.get(activation).subscribe(message => {
          this.snackBar.open(message, 'OK', { duration: 7000 });
        });
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  pageNavigation(event: { count: number; pageSize: number; limit: number; offset: number }) {
    this.isAdmin
      ? this.router.navigate(['/users-management', event.offset + 1])
      : this.router.navigate(['/admin-details/' + this.adminId + '/admin-users-list', event.offset + 1]);
  }

  filterUsers(userIds: number[]) {
    if (this.isAdmin) {
      this.router.navigate(['/users-management'], { queryParams: { userIds } });
    } else {
      this.router.navigate(['/admin-details/' + this.adminId + '/admin-users-list'], { queryParams: { userIds } });
    }
  }

  fetchUsers(query: UserQuery) {
    this.userService.getUserList(query).subscribe({
      next: res => {
        this.usersAutoCompleteList.next(res.data.listing);
      },
      error: err => {
        this.handleErrors.handleErrors(err);
      },
    });
  }

  addNewUser() {
    this.router.navigate(['/', { outlets: { dialog: ['add-user'] } }]);
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
