import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Admin } from 'app/models/admin.model';
import { FilterQuery } from 'app/models/loggin.model';
import { omitBy, isEmpty } from 'lodash';
import { Observable, ReplaySubject, Subject, debounceTime, distinctUntilChanged, of, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'super-admin-credit-log-filter',
  templateUrl: './super-admin-credit-log-filter.component.html',
  styleUrls: ['./super-admin-credit-log-filter.component.css'],
})
export class SuperAdminCreditLogFilterComponent implements OnDestroy, OnChanges {
  loggingFiltreForm: FormGroup;
  @Input() searchedDates: { startDate: Date; endDate: Date };
  @Input() admins: Admin[];
  @Input() searchedAdmins: Admin[];
  @Output() filterQuery = new EventEmitter<FilterQuery>();
  @Output() queryAdmins = new EventEmitter<{ like: string; exclude: number[] }>();

  adminListSubject = new ReplaySubject<Admin[]>(1);
  filteredAdmins: Observable<Admin[]>;
  maxFirstDate = new Date();
  minLastDate: Date = null;
  maxLastDate = new Date();

  @ViewChild('adminInput') adminInput: ElementRef<HTMLInputElement>;
  private readonly unsubscribeSubject: Subject<void>;

  constructor() {
    this.unsubscribeSubject = new Subject<void>();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.loggingFiltreForm) {
      this.buildForm();
    }

    if (changes['searchedAdmins'] && changes['searchedAdmins'].previousValue === null && changes['searchedAdmins'].currentValue) {
      this.loggingFiltreForm.get('admins').setValue(this.searchedAdmins);
      this.searchedAdmins = null;
    }

    if (changes['searchedDates'] && changes['searchedDates'].previousValue === undefined && changes['searchedDates'].currentValue) {
      this.loggingFiltreForm.get('endDate').setValue(this.searchedDates['endDate'] ? new Date(+this.searchedDates['endDate']) : null);
      this.loggingFiltreForm.get('startDate').setValue(this.searchedDates['startDate'] ? new Date(+this.searchedDates['startDate']) : null);
    }

    this.adminListSubject.next(this.admins);
  }

  private buildForm() {
    this.loggingFiltreForm = new FormGroup({
      admins: new FormControl(null),
      adminSearch: new FormControl(null),
      startDate: new FormControl(null),
      endDate: new FormControl(null),
    });

    this.filteredAdmins = this.loggingFiltreForm.get('adminSearch').valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap(admin => (admin?.length > 2 ? this.requestAutoCompleteItems(admin) : of([]))),
    );

    this.loggingFiltreForm.controls['startDate'].valueChanges
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(fDate => (this.minLastDate = fDate));
    this.loggingFiltreForm.controls['endDate'].valueChanges
      .pipe(takeUntil(this.unsubscribeSubject))
      .subscribe(lDate => (this.maxFirstDate = lDate));
  }

  public requestAutoCompleteItems = (adminName: string): Observable<{ id: number; value: string; fullName: string }[]> => {
    this.getAdmins(adminName);

    return this.admins ? this.adminListSubject : of([]);
  };

  getAdmins(adminName: string) {
    const admins = this.loggingFiltreForm.controls['admins'].value || [];
    this.queryAdmins.emit(
      omitBy(
        {
          like: adminName,
          exclude: admins.map(m => m.id),
        },
        isEmpty,
      ),
    );
  }

  search(form: { admins: { id: number; value: string }[] }) {
    const admins = form.admins && form.admins.length > 0 ? form.admins.map(a => a.id).join(',') : null;
    const startDate = this.loggingFiltreForm.controls['startDate'].value;
    const endDate = this.loggingFiltreForm.controls['endDate'].value;
    this.filterQuery.emit({
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
      adminIds: admins,
    });
  }

  remove(admin: Admin): void {
    const admins: Admin[] = this.loggingFiltreForm.get('admins').value;
    this.loggingFiltreForm.get('admins').setValue(admins.filter(item => item.id !== admin.id));
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value: Admin[] = this.loggingFiltreForm.get('admins').value ?? [];
    this.loggingFiltreForm.get('admins').setValue([...value, event.option.value]);

    this.adminInput.nativeElement.value = '';
    this.loggingFiltreForm.get('adminSearch').setValue(null);
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }
}
