import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EntityList, Messages } from 'app/models/entity-list.model';
import { ExtendedSerie, Serie } from '../models/serie.model';
import { MagicTranslateService } from 'app/app-services';

@Component({
  selector: 'serie-list',
  templateUrl: './serie-list.component.html',
  styleUrls: ['./serie-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SerieListComponent implements OnInit {
  @Input() serieList: EntityList<ExtendedSerie>;
  @Input() offset: number;

  @Output() openDeleteSerieDialog = new EventEmitter();
  @Output() tablePagesNavigation = new EventEmitter();
  @Output() routeNavigate = new EventEmitter();

  messages$: Messages;

  constructor(private readonly magicTranslateService: MagicTranslateService) {}

  ngOnInit() {
    this.messages$ = this.magicTranslateService.getMessage();
  }

  deleteSerie(serie: Serie) {
    this.openDeleteSerieDialog.emit(serie);
  }

  handlePages(event: { count: number; pageSize: number; limit: number; offset: number }) {
    this.tablePagesNavigation.emit(event);
  }

  navigate(serieId: number) {
    this.routeNavigate.emit(serieId);
  }
}
